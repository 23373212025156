@tailwind components;
@tailwind utilities;

@import "assets/tropp-theme/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

.rtl {
  direction: rtl;

  label {
    left: unset;
    right: 0.5rem;
  }

  .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    transform: rotate(180deg) !important;
  }
}

.blocked-bg {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.bg-primary {
  background-color: var(--primary-color);
}

.color-primary {
  color: var(--primary-color);
}

.side-menu {
  width: 20vw;
  border: 1px solid var(--gray-300);
  border-radius: 8px;

  .subtitle {
    color: var(--gray-400);
  }
}

.pac-container {
  z-index: 10000;
}

.flex-grid {
  display: grid;

  &:has(:nth-child(n+4)) {
    display: grid;
  }

  &:not(:has(:nth-child(n+4))) {
    display: flex;
  }
}

.cut-off {
  width: calc(20vw - 112px - 10px - 2rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container .p-datatable-wrapper {
  height: 45vh;
}

.small-paginator {
  .p-paginator-page {
    min-width: 2rem;
    height: 2rem;
  }

  .p-dropdown {
    height: 2rem;
  }

  .p-inputtext {
    padding: 0.25rem;
    display: flex;
    align-items: center;
  }

  .p-paginator-prev, .p-paginator-last, .p-paginator-next, .p-paginator-first {
    min-width: 1rem;
    height: 1rem;
  }
}

@media (max-width: 768px) {
  .p-dialog {
    width: 90vw !important;
  }
}
